import React from "react";
import Header from "../../components/global/header/header";
import Hero from "../../components/heros/alternate";
import TextBlock from "../../components/global/textblock/standard";
import ThreeColumns from "../../components/global/textblock/threeColumns";
import CallToAction from "../../components/global/cta";
import MainContent from "../../components/products/main-content";
import Features from "../../components/products/features";
import Footer from "../../components/global/footer/footer";
import PageTransition from "gatsby-plugin-page-transitions";
import Equalizer from "react-equalizer";
import Slider from "../../components/sliders/product-slider/slider";

// Images
import ctaImage from "../../images/7Z8A0083_Output.jpg";
import accounting from "../../images/business-solutions/accounting2.jpg";
import { withIntl } from "../../i18n";

// Icons
import icon1 from "../../images/sportsbook-icons/key.svg";
import icon2 from "../../images/sportsbook-icons/responsive.svg";
import icon3 from "../../images/sportsbook-icons/dna.svg";
import icon4 from "../../images/sportsbook-icons/check-all.svg";
import icon5 from "../../images/sportsbook-icons/networking.svg";
import icon6 from "../../images/sportsbook-icons/components.svg";
import icon7 from "../../images/sportsbook-icons/mobile-card.svg";
import icon8 from "../../images/sportsbook-icons/wallet.svg";
import icon9 from "../../images/sportsbook-icons/user.svg";
import sportsbookiPhone from "../../images/sportsbook-mac.jpg";
import { FormattedMessage } from "react-intl";

// import macImg from "../../images/iphone-hand-cropped.jpg";
// import ipadImg from "../../images/ipad-cropped.jpg";
import businessImg from "../../images/business-img.jpg";

const features = [
	{
		icon: icon1,
		heading: "feature_rich_yet_so_simple",
		content: "feature_rich_yet_so_simple_para"
	},

	{
		icon: icon2,
		heading: "intuitive_design",
		content: "intuitive_design_para"
	},

	{
		icon: icon3,
		heading: "highly_customizable",
		content: "highly_customizable_para"
	},

	{
		icon: icon4,
		heading: "mobile_first",
		content: "mobile_first_para"
	},

	{
		icon: icon5,
		heading: "robust_yet_affordable",
		content: "robust_yet_affordable_para"
	},

	{
		icon: icon6,
		heading: "standard_financial_reports",
		content: "standard_financial_reports_para"
	},

	{
		icon: icon7,
		heading: "accountant_auditor_access",
		content: "accountant_auditor_access_para"
	},
];

class ProductPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contactOpen: false
		};
	}

	getNodes(equalizerComponent, equalizerElement) {
		return [this.refs.thisThing, this.refs.thatThing];
	}

	toggleContact = () => {
		this.setState({
			contactOpen: !this.state.contactOpen
		});
		document.body.classList.toggle("noscroll");
	};

	componentDidMount() {
        if(document.body.classList.contains('noscroll')) {
            document.body.classList.remove('noscroll');
        }
    }

	render() {
		return (
			<PageTransition
				defaultStyle={{
					transition: "all 1100ms cubic-bezier(0.8, 0, 0.2, 1)",
					position: "relative",
					width: "100%",
					opacity: 0,
					maxWidth: "2000px"
				}}
				transitionStyles={{
					entering: { opacity: 1 },
					entered: { opacity: 1 },
					exiting: { opacity: 0 }
				}}

				transitionTime={1250}
			>
				<Header
					headerStyle="std"
					contactOpen={this.state.contactOpen}
					action={() => this.toggleContact()}
					{...this.props}
				/>

				<Hero
					featImage={accounting}
					pageTitleSub="swift"
					pageTitleMain="accounting"
				/>

				<TextBlock content="accounting_para" />

				<MainContent
					featImage={[businessImg]}
					subTitle="suitable"
					mainTitle="for_every_business"
					bodyCopy1="every_business_needs_para"
				/>

				<Features
					subTitle="platform"
					mainTitle="features_include"
					features={features}
				/>

				<section className="textblock bg-white">
					{/* Content */}
					{/* <Equalizer
						nodes={this.getNodes.bind(this)}
						className="row expanded collapse"
						byRow={false}
						property="height"
						enabled={() => window.matchMedia("(min-width: 1200px)").matches}
					>
					</Equalizer> */}
				</section>

				<CallToAction
					image={ctaImage}
					heading="tailor_made_perfect_fit"
					content="lets_get_in_touch"
					buttonLabel="contact_us"
					action={() => this.toggleContact()}
				/>

				<Footer />
			</PageTransition>
		);
	}
}

export default withIntl(ProductPage);




// ./src/pages/solutions/sportsbooks.jsx
import React, { Component } from 'react'

class ThreeColumns extends Component {
  render() {
    const {
      title,
      contentPrimary,
      contentSecondary,
      contentTertiary,
    } = this.props

    return (
      <section className="pad mpad bg-white column-wrapper">
        <h2 className="dark section-title">
          {title}
        </h2>
        <div className="three-columns">
          <p className="dark single-column">
            {contentPrimary}
          </p>
          <p className="dark single-column">
            {contentSecondary}
          </p>
          <p className="dark single-column">
            {contentTertiary}
          </p>
        </div>
      </section>
    );
  }
};

export default ThreeColumns




